import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to fetch the app setting
export const getAppSetting = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(`${baseUrl}/superAdmin/getSettings`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to update a app setting
export const updateAppSetting = async (appData: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const config: AxiosRequestConfig = {
        headers: headers,
      };
      const response = await axios.post(
        `${baseUrl}/superAdmin/upsertSetting`,
        appData,
        config
      );
      return response.data;
    } catch (error) {
      errorHandler(error);
    }
  };