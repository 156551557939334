/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { createPlan, getProductList } from "services/plans";
import Loader from "components/Loader/Loader";
const AddPlanModal = ({ isOpen, handleClose, setRefresh, refresh }: any) => {
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchProductList = async () => {
        try {
          const data = await getProductList();
          setProductList(data?.product);
        } catch (error) {}
      };
      fetchProductList();
    }
  }, [isOpen]);
  const validationSchema = Yup.object().shape({
    package_name: Yup.string().required("Package name is required"),
    daily_minutes: Yup.number().required("Daily minutes is required"),
    rate_per_minute: Yup.number().required("Rate per minute is required"),
    playground_rate: Yup.number().required("Playground rate is required"),
    server_fee: Yup.number().required("Server fee required"),
    monthly_subscription_fee: Yup.number().required(
      "Monthly subscription fee is required"
    ),
    yearly_subscription_fee: Yup.number().required(
      "Yearly subscription fee is required"
    ),
    estimated_monthly_cost: Yup.number().required(
      "Estimated monthly cost is required"
    ),
    // interval: Yup.string().required("Interval is required"),
    product_id: Yup.string().required("Product is required"),
  });

  const formik = useFormik({
    initialValues: {
      package_name: "",
      daily_minutes: "",
      rate_per_minute: "",
      playground_rate: "",
      server_fee: "",
      monthly_subscription_fee: "",
      yearly_subscription_fee: "",
      estimated_monthly_cost: "",
      // interval: "",
      product_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        package_name: values.package_name,
        daily_minutes: values.daily_minutes,
        rate_per_minute: values.rate_per_minute,
        playground_rate: values.playground_rate,
        server_fee: values.server_fee,
        monthly_subscription_fee: values.monthly_subscription_fee,
        yearly_subscription_fee: values.yearly_subscription_fee,
        estimated_monthly_cost: values.estimated_monthly_cost,
        // interval: values.interval,
        // interval_count: 1,
        product_id: values.product_id,
      };
      setIsLoading(true);
      createPlan(payload)
        .then((response: any) => {
          if (response.success) {
            setRefresh(!refresh);
            toast({
              description: response.message,
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            handleClose();
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            throw new Error("Plan creation failed");
          }
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          console.error("Error creating plan:", error);
        });
    },
  });

  const closeForm = () => {
    handleClose();
    formik.resetForm();
  };
  return (
    <>
      {isLoading && <Loader />}
      <Drawer
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={closeForm}
        size={"xxl"}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"700px"} className="dark:bg-navy-900">
          <DrawerHeader>Add Plan</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl className="mb-4">
              <FormLabel>Package Name *</FormLabel>
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="package_name"
                type="text"
                value={formik.values.package_name}
                onChange={formik.handleChange}
              />
              {formik.touched.package_name && formik.errors.package_name ? (
                <div className="text-red-500">
                  {formik.errors.package_name.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Daily Minutes *</FormLabel>
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="daily_minutes"
                type="number"
                value={formik.values.daily_minutes}
                onChange={formik.handleChange}
              />
              {formik.touched.daily_minutes && formik.errors.daily_minutes ? (
                <div className="text-red-500">
                  {formik.errors.daily_minutes.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Rate Per Minute *</FormLabel>
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="rate_per_minute"
                type="number"
                value={formik.values.rate_per_minute}
                onChange={formik.handleChange}
              />
              {formik.touched.rate_per_minute &&
              formik.errors.rate_per_minute ? (
                <div className="text-red-500">
                  {formik.errors.rate_per_minute.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Playground Rate *</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter amount"
                  className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="playground_rate"
                  type="number"
                  value={formik.values.playground_rate}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.touched.playground_rate &&
              formik.errors.playground_rate ? (
                <div className="text-red-500">
                  {formik.errors.playground_rate.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Server Fee *</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter amount"
                  className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="server_fee"
                  type="number"
                  value={formik.values.server_fee}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.touched.server_fee && formik.errors.server_fee ? (
                <div className="text-red-500">
                  {formik.errors.server_fee.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Monthly Subscription Fee *</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter amount"
                  className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="monthly_subscription_fee"
                  type="number"
                  value={formik.values.monthly_subscription_fee}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.touched.monthly_subscription_fee &&
              formik.errors.monthly_subscription_fee ? (
                <div className="text-red-500">
                  {formik.errors.monthly_subscription_fee.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Yearly Subscription Fee *</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter amount"
                  className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="yearly_subscription_fee"
                  type="number"
                  value={formik.values.yearly_subscription_fee}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.touched.yearly_subscription_fee &&
              formik.errors.yearly_subscription_fee ? (
                <div className="text-red-500">
                  {formik.errors.yearly_subscription_fee.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl className="mb-4">
              <FormLabel>Estimated Monthly Cost *</FormLabel>
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="estimated_monthly_cost"
                type="number"
                value={formik.values.estimated_monthly_cost}
                onChange={formik.handleChange}
              />
              {formik.touched.estimated_monthly_cost &&
              formik.errors.estimated_monthly_cost ? (
                <div className="text-red-500">
                  {formik.errors.estimated_monthly_cost.toString()}
                </div>
              ) : null}
            </FormControl>
            {/* <FormControl className="mb-4">
              <FormLabel>Interval *</FormLabel>
              <select
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="interval"
                name="interval"
                value={formik.values.interval}
                onChange={formik.handleChange}
              >
                <option value="">Select interval...</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
              {formik.touched.interval && formik.errors.interval ? (
                <div className="text-red-500">
                  {formik.errors.interval.toString()}
                </div>
              ) : null}
            </FormControl> */}
            <FormControl className="mb-4">
              <FormLabel>Product *</FormLabel>
              <select
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="product_id"
                name="product_id"
                value={formik.values.product_id}
                onChange={formik.handleChange}
              >
                <option value="">Select product...</option>
                {productList &&
                  productList.map((op, i) => (
                    <option key={i} value={op.product_id}>
                      {op.name}
                    </option>
                  ))}
              </select>
              {formik.touched.product_id && formik.errors.product_id ? (
                <div className="text-red-500">
                  {formik.errors.product_id.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                closeForm();
              }}
            >
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddPlanModal;
