import React, { useEffect, useState } from "react";
// import { MdDelete, MdEdit } from "react-icons/md";
// import DeleteModal from "modal/deleteModal";
// import UpdateVoiceModal from "modal/updateVaoiceModal";
// import ContactUsModal from "modal/contactUsModal";
import Loader from "components/Loader/Loader";
import { getContactList } from "services/contactUs";
import Swal from "sweetalert2";
import Pagination from "components/pagenation";

const WebsiteEnquiry = () => {
  // const [isContactUs, setIsContactUs] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isEditModal, setIsEditModalOpen] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState(null);
  // const [isConfirm, setIsConfirm] = useState(false);
  // const [refresh, setRefresh] = useState(false);
  // const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentContact = contactList?.slice(indexOfFirstUser, indexOfLastUser);
  // const handleContactUsClose = () => {
  //   setIsContactUs(false);
  // };

  // const handleContactUsModal = () => {
  //   setIsContactUs(!isContactUs);
  // };

  // const handleClose = () => {
  //   setIsModalOpen(false);
  // };

  // const handleModal = (item: any) => {
  //   setIsModalOpen(!isModalOpen);
  //   setSelectedItemId(item);
  // };

  // // const handleEditClose = () => {
  //   setIsEditModalOpen(false);
  // };

  // const handleEditModal = (item: any) => {
  //   setIsEditModalOpen(!isEditModal);
  //   setSelectedItemId(item);
  // };

  useEffect(() => {
    const fetchVoiceList = async () => {
      setLoading(true);
      try {
        const data = await getContactList();
        setContactList(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch voice list. Please try again.",
        });
      }
    };
    fetchVoiceList();
  }, []);

  // useEffect(() => {
  //   if (isConfirm === true) {
  //     deleteCallVoice(rowId)
  //       .then((response) => {
  //         if (response.success) {
  //           handleClose();
  //           setRefresh(!refresh);
  //           toast({
  //             description: response?.message,
  //             status: "success",
  //             duration: 5000,
  //             isClosable: true,
  //             position: "top-left",
  //           });
  //         } else {
  //           toast({
  //             description: response?.message,
  //             status: "info",
  //             duration: 5000,
  //             isClosable: true,
  //             position: "top-left",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error Deleting Assistant:", error);
  //       });
  //   }
  // }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div className="flex justify-end">
            <button
              onClick={() => handleContactUsModal()}
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
            >
              Add ContactUs
            </button>
          </div> */}
          <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
            <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Phone Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Website
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Massage
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {currentContact?.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b ${
                      index % 2 === 0 ? " even:bg-gray-50" : "odd:bg-white"
                    } ${
                      index % 2 === 0
                        ? "dark:bg-navy-900 dark:text-white"
                        : "dark:bg-navy-900 dark:text-white"
                    }`}
                  >
                    <th
                      data-label="Name"
                      className="whitespace-nowrap px-6 py-4 font-medium dark:text-white"
                    >
                      {item.full_name}
                    </th>
                    <td className="whitespace-nowrap px-6 py-4 font-medium dark:text-white" data-label="Email">
                      {item.email ? item.email : "-"}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium dark:text-white" data-label="Phone Number">
                      {item.phone ? item.phone : "-"}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium dark:text-white" data-label="Website">
                      {item.website ? item.website : "-"}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium dark:text-white" data-label="Message">
                      {item.message ? item.message : "-"}
                    </td>
                    {/* <td className="flex gap-2 px-6 py-4">
                      <div className="flex gap-4">
                        <MdEdit
                          //   onClick={() => handleEditModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                        />
                        <MdDelete
                          onClick={() => handleModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                        />
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {/* <DeleteModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        rowData={selectedItemId}
      />
      <ContactUsModal isOpen={isContactUs} handleClose={handleContactUsClose} />
      <UpdateVoiceModal
        isOpen={isEditModal}
        handleClose={handleEditClose}
        rowData={selectedItemId}
      /> */}
      <Pagination
        perPage={perPage}
        allTotal={contactList?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default WebsiteEnquiry;
