import React, { useRef } from "react";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { updateUserAmount } from "services/user";

const AddAmountModal = ({ isOpen, handleClose, setRefresh, refresh, userId }: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required"),
  });
  const formik = useFormik({
    initialValues: {
        amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
        let payload = {
            amount: values.amount,
            userId: userId
        }
        updateUserAmount(payload)
        .then((response: any) => {
          if (response.success) {
            setRefresh(!refresh);
            toast({
              description: response.message,
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            handleClose();
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            throw new Error("Template creation failed");
          }
        })
        .catch((error: any) => {
          console.error("Error creating template:", error);
        });
    },
  });

  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent maxW={"500px"} className="dark:bg-navy-900">
          <ModalHeader>Add Amount</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
                <FormLabel>Amount *</FormLabel>
                <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                >
                    $
                </InputLeftElement>
                <Input
                    placeholder="Enter amount"
                    className="mb-4 w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="amount"
                    type="number"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                />
                </InputGroup>
                {formik.touched.amount && formik.errors.amount ? (
                <div className="text-red-500">
                    {formik.errors.amount.toString()}
                </div>
                ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAmountModal;
