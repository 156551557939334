/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, useToast } from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import Loader from "components/Loader/Loader";
import { MdDelete } from "react-icons/md";
import { RiCoupon3Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import DeleteModal from "modal/deleteModal";
import AddPlanModal from "modal/AddPlanModal";
import { numberWithCommasAndDollar } from "utils/utils";
import { getPlanList, deletePlan } from "services/plans";
// import UpdatePlanModal from "modal/UpdatePlanModal";

const Plans = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [isAddPlan, setIsAddPlan] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  // const [isModalEditPlan, setIsModalEditPlan] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState(null);
  const [rowId, setRowId] = useState("");

  const handleAddPlanClose = () => {
    setIsAddPlan(false);
  };

  const createNewPlan = () => {
    setIsAddPlan(!isAddPlan);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (item: any) => {
    setIsConfirm(false);
    setIsModalOpen(!isModalOpen);
    setRowId(item?.package_id);
  };

  // const handleEditPlanClose = () => {
  //   setIsModalEditPlan(false);
  // };

  // const handleEditPlan = (item: any) => {
  //   setIsModalEditPlan(!isModalEditPlan);
  //   setSelectedItemId(item);
  // };

  useEffect(() => {
    const fetchPlanList = async () => {
      setLoading(true);
      try {
        const data = await getPlanList();
        setCardData(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch Plan list. Please try again.",
        });
      }
    };
    fetchPlanList();
  }, [refresh]);

  useEffect(() => {
    if (isConfirm === true) {
      deletePlan(rowId)
        .then((response: any) => {
          if (response.success) {
            handleClose();
            setRefresh(!refresh);
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: any) => {
          handleClose();
          console.error("Error Deleting Assistant:", error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="mt-8">
        {loading && <Loader />}
        <div className="mb-3 flex justify-end">
          <button
            onClick={() => createNewPlan()}
            className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            ADD NEW PLAN
          </button>
        </div>
        {!loading && cardData && cardData?.length === 0 ? (
          <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                <RiCoupon3Fill />
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                No Plan
              </h4>
              <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                Get started by creating a new Plan.
              </p>
              <button
                onClick={() => createNewPlan()}
                className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                <GoPlus className="h-7 w-7" />
                ADD NEW PLAN
              </button>
            </div>
          </Card>
        ) : (
          <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
            <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2  text-xs uppercase  dark:bg-navy-900 dark:text-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Package Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Daily Minutes
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rate Per Minute
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Playground Rate
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Interval
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Subscription Fee
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Estimated Monthly Cost
                  </th>
                  <th scope="col" className="px-6 py-3">
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {cardData?.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b ${
                      index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                    } ${
                      index % 2 === 0 ? "dark:bg-navy-800" : "dark:bg-navy-800"
                    }`}
                  >
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Package Name"
                    >
                      {item?.package_name}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Daily Minutes"
                    >
                      {item?.daily_minutes}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Rate Per Minute"
                    >
                      {item?.rate_per_minute}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Playground Rate"
                    >
                      {numberWithCommasAndDollar(item?.playground_rate || 0)}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Interval"
                    >
                      {item?.interval === "month" ? "Monthly" : "Yearly"}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Subscription Fee"
                    >
                      {item?.interval === "month"
                        ? `${numberWithCommasAndDollar(
                            item?.monthly_subscription_fee || 0
                          )}`
                        : `${numberWithCommasAndDollar(
                            item?.yearly_subscription_fee || 0
                          )}`}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 dark:text-white"
                      data-label="Estimated Monthly Cost"
                    >
                      {numberWithCommasAndDollar(
                        item?.estimated_monthly_cost || 0
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex gap-4">
                        {/* <MdEdit
                          onClick={() => handleEditPlan(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                        /> */}
                        <MdDelete
                          onClick={() => handleModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        setIsConfirm={setIsConfirm}
      />

      <AddPlanModal
        isOpen={isAddPlan}
        handleClose={handleAddPlanClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      {/* <UpdatePlanModal
        isOpen={isModalEditPlan}
        handleClose={handleEditPlanClose}
        rowData={selectedItemId}
        setRefresh={setRefresh}
        refresh={refresh}
      /> */}
    </div>
  );
};

export default Plans;
