/* eslint-disable react-hooks/exhaustive-deps */
import {
    Avatar,
  AvatarBadge,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { getAppSetting, updateAppSetting } from "services/appSetting";

const AppSetting = () => {
    const toast = useToast();
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const fileInputRef4 = useRef(null);
  const [loading, setLoading] = useState(false);

  const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string | null }>({
    logo_dark: null,
    logo_light: null,
    short_logo: null,
    favicon_logo: null,
    auth_page_side_banner: null,
  });

  useEffect(() => {
    fetchAppData();
  }, []);

  const validationSchema = Yup.object().shape({
    logo_dark: Yup.mixed()
      .required("Dark logo is required")
      .test("fileType", "Unsupported File Format", (value) => {
        if (value && value instanceof File) {
          return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
        }
        return true; // No file uploaded yet
      }),
    logo_light: Yup.mixed()
      .required("Light logo is required")
      .test("fileType", "Unsupported File Format", (value) => {
        if (value && value instanceof File) {
          return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
        }
        return true;
      }),
    favicon_logo: Yup.mixed()
      .required("Favicon is required")
      .test("fileType", "Unsupported File Format", (value) => {
        if (value && value instanceof File) {
          return ["image/x-icon", "image/jpeg", "image/png"].includes(
            value.type
          );
        }
        return true;
      }),
    auth_page_side_banner: Yup.mixed()
      .required("Banner is required")
      .test("fileType", "Unsupported File Format", (value) => {
        if (value && value instanceof File) {
          return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
        }
        return true;
      }),
    short_logo: Yup.mixed()
        .required("Banner is required")
        .test("fileType", "Unsupported File Format", (value) => {
            if (value && value instanceof File) {
            return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
            }
            return true;
        }),
    website_name: Yup.string().required("Website name is required"),
    website_description: Yup.string().required("Website description is required"),
    terms_and_conditions_url: Yup.string().required("Terms and conditions url is required").url("Invalid Url"),
    privacy_policy_url: Yup.string().required("Privacy policy url is required").url("Invalid Url"),
  });

  const formik = useFormik({
    initialValues: {
        logo_dark: null,
        logo_light: null,
        short_logo: null,
        favicon_logo: null,
        auth_page_side_banner: null,
        website_name: "",
        website_description: "",
        terms_and_conditions_url: "",
        privacy_policy_url: "",
        is_register_new_user_allowed: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
        let formData = new FormData();
        formData.append("logo_dark", values.logo_dark);
        formData.append("logo_light", values.logo_light);
        formData.append("short_logo", values.short_logo);
        formData.append("favicon_logo", values.favicon_logo);
        formData.append("auth_page_side_banner", values.auth_page_side_banner);
        formData.append("website_name", values.website_name);
        formData.append("website_description", values.website_description);
        formData.append("terms_and_conditions_url", values.terms_and_conditions_url);
        formData.append("privacy_policy_url", values.privacy_policy_url);
        formData.append("is_register_new_user_allowed", values.is_register_new_user_allowed);

        setLoading (true);
        updateAppSetting(formData)
            .then((response: any) => {
            if (response.success) {
                toast({
                    description: response.message,
                    status: "success",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
            } else {
                toast({
                    description: response.message,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
                throw new Error("Plan creation failed");
            }
            setLoading (false);
            })
            .catch((error: any) => {
            setLoading (false);
            console.error("Error creating plan:", error);
            });
    }
  });
  const fetchAppData = async () => {
    setLoading(true);
    try {
      const data = await getAppSetting();
      if (data?.data) {
        // setWithdrawalList(data?.data[0]);
        if (data?.data[0]) {
            formik.setFieldValue("logo_dark", data?.data[0]?.logo_dark);
            formik.setFieldValue("logo_light", data?.data[0]?.logo_light);
            formik.setFieldValue("short_logo", data?.data[0]?.short_logo);
            formik.setFieldValue("favicon_logo", data?.data[0]?.favicon_logo);
            formik.setFieldValue("auth_page_side_banner", data?.data[0]?.auth_page_side_banner);
            formik.setFieldValue("website_name", data?.data[0]?.website_name);
            formik.setFieldValue("website_description", data?.data[0]?.website_description);
            formik.setFieldValue("terms_and_conditions_url", data?.data[0]?.terms_and_conditions_url);
            formik.setFieldValue("privacy_policy_url", data?.data[0]?.privacy_policy_url);
            formik.setFieldValue("is_register_new_user_allowed", data?.data[0]?.is_register_new_user_allowed);
            setImagePreviews({
                logo_dark: process.env.REACT_APP_BASE_URL_IMAGES + data?.data[0]?.logo_dark,
                logo_light: process.env.REACT_APP_BASE_URL_IMAGES + data?.data[0]?.logo_light,
                short_logo: process.env.REACT_APP_BASE_URL_IMAGES + data?.data[0]?.short_logo,
                favicon_logo: process.env.REACT_APP_BASE_URL_IMAGES + data?.data[0]?.favicon_logo,
                auth_page_side_banner: process.env.REACT_APP_BASE_URL_IMAGES + data?.data[0]?.auth_page_side_banner,
            })
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to fetch App Setting. Please try again.",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const file = event.currentTarget.files![0];
    if (file) {
    //   setImages((prev) => ({ ...prev, [field]: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prev) => ({ ...prev, [field]: reader.result as string }));
      };
      reader.readAsDataURL(file);
      formik.setFieldValue(field, file); // Update Formik state
    }
  };
  const handleFileClick = (status: any) => {
    if (status === "logo_dark") {
        fileInputRef.current.click();
    }
    if (status === "logo_light") {
        fileInputRef1.current.click();
    }
    if (status === "short_logo") {
        fileInputRef2.current.click();
    }
    if (status === "auth_page_side_banner") {
        fileInputRef4.current.click();
    }
    if (status === "favicon_logo") {
        fileInputRef3.current.click();
    }
  };
  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
          <div className="mt-3 flex flex-col gap-6 md:flex-row">
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
              <FormControl>
                <FormLabel>Dark Logo *</FormLabel>
                <Avatar
                    size="xl"
                    name="logo_dark"
                    borderRadius="0"
                    width="100%"
                    height="300px"
                    objectFit="cover"
                    src={imagePreviews.logo_dark || ""}
                    >
                    <AvatarBadge
                        boxSize="1.25em"
                        className="bg-blue-700"
                        border="4px solid white"
                        width="35px"
                        height="35px"
                        padding={"4px"}
                        right={"2"}
                        bottom={"2"}
                        cursor={"pointer"}
                        onClick={() => handleFileClick("logo_dark")}
                    >
                        <MdEdit />
                    </AvatarBadge>
                </Avatar>
                <input
                    ref={fileInputRef}
                    id="logo_dark"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, "logo_dark")}
                />
                {formik.touched.logo_dark && formik.errors.logo_dark ? (
                <div className="text-red-500">
                    {formik.errors.logo_dark.toString()}
                </div>
                ) : null}
            </FormControl>
            </div>
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
              <FormControl>
                <FormLabel>Light Logo*</FormLabel>
                <Avatar
                    size="xl"
                    name="logo_light"
                    borderRadius="0"
                    width="100%"
                    height="300px"
                    objectFit="cover"
                    src={imagePreviews.logo_light || ""}
                    >
                    <AvatarBadge
                        boxSize="1.25em"
                        className="bg-blue-700"
                        border="4px solid white"
                        width="35px"
                        height="35px"
                        padding={"4px"}
                        right={"2"}
                        bottom={"2"}
                        cursor={"pointer"}
                        onClick={() => handleFileClick("logo_light")}
                    >
                        <MdEdit />
                    </AvatarBadge>
                </Avatar>
                <input
                    ref={fileInputRef1}
                    id="logo_light"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, "logo_light")}
                />
                {formik.touched.logo_light && formik.errors.logo_light ? (
                <div className="text-red-500">
                    {formik.errors.logo_light.toString()}
                </div>
                ) : null}
            </FormControl>
            </div>
          </div>
          <div className="mt-3 flex flex-col gap-6 md:flex-row">
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
              <FormControl>
                <FormLabel>Sort Logo *</FormLabel>
                <Avatar
                    size="xl"
                    name="short_logo"
                    borderRadius="0"
                    width="100%"
                    height="300px"
                    objectFit="cover"
                    src={imagePreviews.short_logo || ""}
                    >
                    <AvatarBadge
                        boxSize="1.25em"
                        className="bg-blue-700"
                        border="4px solid white"
                        width="35px"
                        height="35px"
                        padding={"4px"}
                        right={"2"}
                        bottom={"2"}
                        cursor={"pointer"}
                        onClick={() => handleFileClick("short_logo")}
                    >
                        <MdEdit />
                    </AvatarBadge>
                </Avatar>
                <input
                    ref={fileInputRef2}
                    id="short_logo"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, "short_logo")}
                />
                {formik.touched.short_logo && formik.errors.short_logo ? (
                <div className="text-red-500">
                    {formik.errors.short_logo.toString()}
                </div>
                ) : null}
            </FormControl>
            </div>
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
              <FormControl>
                <FormLabel>Auth Banner *</FormLabel>
                <Avatar
                    size="xl"
                    name="auth_page_side_banner"
                    borderRadius="0"
                    width="100%"
                    height="300px"
                    objectFit="cover"
                    src={imagePreviews.auth_page_side_banner || ""}
                    >
                    <AvatarBadge
                        boxSize="1.25em"
                        className="bg-blue-700"
                        border="4px solid white"
                        width="35px"
                        height="35px"
                        padding={"4px"}
                        right={"2"}
                        bottom={"2"}
                        cursor={"pointer"}
                        onClick={() => handleFileClick("auth_page_side_banner")}
                    >
                        <MdEdit />
                    </AvatarBadge>
                </Avatar>
                <input
                    ref={fileInputRef4}
                    id="auth_page_side_banner"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, "auth_page_side_banner")}
                />
                {formik.touched.auth_page_side_banner && formik.errors.auth_page_side_banner ? (
                <div className="text-red-500">
                    {formik.errors.auth_page_side_banner.toString()}
                </div>
                ) : null}
            </FormControl>
            </div>
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
              <FormControl>
                <FormLabel>Favicon Icon *</FormLabel>
                <Avatar
                    size="xl"
                    name="favicon_logo"
                    borderRadius="0"
                    width="100%"
                    height="300px"
                    objectFit="cover"
                    src={imagePreviews.favicon_logo || ""}
                    >
                    <AvatarBadge
                        boxSize="1.25em"
                        className="bg-blue-700"
                        border="4px solid white"
                        width="35px"
                        height="35px"
                        padding={"4px"}
                        right={"2"}
                        bottom={"2"}
                        cursor={"pointer"}
                        onClick={() => handleFileClick("favicon_logo")}
                    >
                        <MdEdit />
                    </AvatarBadge>
                </Avatar>
                <input
                    ref={fileInputRef3}
                    id="favicon_logo"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, "favicon_logo")}
                />
                {formik.touched.favicon_logo && formik.errors.favicon_logo ? (
                <div className="text-red-500">
                    {formik.errors.favicon_logo.toString()}
                </div>
                ) : null}
            </FormControl>
            </div>
          </div>
          <div className="mt-3 flex flex-col gap-6 md:flex-row">
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
                <FormControl className="mb-4">
                    <FormLabel>Website Name *</FormLabel>
                    <input
                    className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="website_name"
                    type="text"
                    value={formik.values.website_name}
                    onChange={formik.handleChange}
                    />
                    {formik.touched.website_name && formik.errors.website_name ? (
                    <div className="text-red-500">
                        {formik.errors.website_name.toString()}
                    </div>
                    ) : null}
                </FormControl>
                <FormControl className="mb-4">
                    <FormLabel>Terms and Conditions Url *</FormLabel>
                    <input
                    className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="terms_and_conditions_url"
                    type="text"
                    value={formik.values.terms_and_conditions_url}
                    onChange={formik.handleChange}
                    />
                    {formik.touched.terms_and_conditions_url && formik.errors.terms_and_conditions_url ? (
                    <div className="text-red-500">
                        {formik.errors.terms_and_conditions_url.toString()}
                    </div>
                    ) : null}
                </FormControl>
                <FormControl className="mb-4">
                    <FormLabel>Privacy Policy Url *</FormLabel>
                    <input
                    className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="privacy_policy_url"
                    type="text"
                    value={formik.values.privacy_policy_url}
                    onChange={formik.handleChange}
                    />
                    {formik.touched.privacy_policy_url && formik.errors.privacy_policy_url ? (
                    <div className="text-red-500">
                        {formik.errors.privacy_policy_url.toString()}
                    </div>
                    ) : null}
                </FormControl>
            </div>
            <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
                <FormControl>
                    <FormLabel>Website Description *</FormLabel>
                    <textarea
                    className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="website_description"
                    rows={5}
                    value={formik.values.website_description}
                    onChange={formik.handleChange}
                    />
                    {formik.touched.website_description && formik.errors.website_description ? (
                    <div className="text-red-500">
                        {formik.errors.website_description.toString()}
                    </div>
                    ) : null}
                </FormControl>
                <FormControl className="mt-14">
                    <Checkbox
                        id="is_register_new_user_allowed"
                        colorScheme="cyan"
                        defaultChecked={formik.values.is_register_new_user_allowed}
                        onChange={formik.handleChange}
                        className="mt-1"
                        >
                        Register New User Allowed
                        </Checkbox>
                    {formik.touched.is_register_new_user_allowed && formik.errors.is_register_new_user_allowed ? (
                    <div className="text-red-500">
                        {formik.errors.is_register_new_user_allowed.toString()}
                    </div>
                    ) : null}
                </FormControl>
            </div>
          </div>
          <div className="text-center">
            <Button
                colorScheme="blue"
                className="mt-5 mb-4"
                onClick={() => formik.handleSubmit()}
            >
                Save Setting
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default AppSetting;
