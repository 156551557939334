/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card } from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import Loader from "components/Loader/Loader";
import { RiCoupon3Fill } from "react-icons/ri";
import Swal from "sweetalert2";
import { getProductList } from "services/plans";
import AddProductModal from "modal/AddProductModal";

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleAddProductClose = () => {
    setIsAddProduct(false);
  };

  const createNewProduct = () => {
    setIsAddProduct(!isAddProduct);
  };

  useEffect(() => {
    const fetchPlanList = async () => {
      setLoading(true);
      try {
        const data = await getProductList();
        setCardData(data?.product);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch Product list. Please try again.",
        });
      }
    };
    fetchPlanList();
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="mt-8">
        {loading && <Loader />}
        <div className="mb-3 flex justify-end">
          <button
            onClick={() => createNewProduct()}
            className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            ADD NEW PRODUCT
          </button>
        </div>
        {!loading && cardData && cardData?.length === 0 ? (
          <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                <RiCoupon3Fill />
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                No Products
              </h4>
              <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                Get started by creating a new Product.
              </p>
              <button
                onClick={() => createNewProduct()}
                className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                <GoPlus className="h-7 w-7" />
                ADD NEW PRODUCT
              </button>
            </div>
          </Card>
        ) : (
          <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
            <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2  text-xs uppercase  dark:bg-navy-900 dark:text-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {cardData?.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b ${
                      index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                    } ${
                      index % 2 === 0 ? "dark:bg-navy-800" : "dark:bg-navy-800"
                    }`}
                  >
                    <td
                      className="text-black w-full md:w-[300px] cursor-pointer px-6  py-4 text-lg dark:text-white"
                      data-label="Product Name"
                    >
                      {item?.name}
                    </td>
                    <td
                      className="text-black cursor-pointer px-6  py-4 text-lg dark:text-white"
                      data-label="Description"
                    >
                      {item?.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <AddProductModal
        isOpen={isAddProduct}
        handleClose={handleAddProductClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default Products;
