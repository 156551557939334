import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to fetch the list of industries
export const getPlanList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/superadmin/listPackage`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to delete an Plan
export const deletePlan = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/superadmin/deletePackage?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to create a new Plan
export const createPlan = async (PlanData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/superadmin/createPackage`,
      PlanData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to update Plan details
export const updatePlanDetails = async (updatedData: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.put(
      `${baseUrl}/superadmin/updatePackage?id=${id}`,
      updatedData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to create a new Plan
export const createProduct = async (productData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/superadmin/create-product`,
      productData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to fetch the list of industries
export const getProductList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/superadmin/product-list`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
